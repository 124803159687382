<template>
  <v-container fluid>
    <v-layout row wrap style="margin: 0px">
      <v-flex v-if="!isConnected && !whatsappRegistered" xs12 sm12 md12 style="padding: 1%">
        <WhatsappConnector :is-connected="isConnected" :whatsapp-registered="whatsappRegistered"
          :set-chat-show="setChatShow" />
      </v-flex>

      <v-flex v-else xs12 sm12 md12 style="padding: 1%">
        <v-card elevation="0" style="background-color: #f6f6f6;">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-dialog ref="dialog" v-model="modal" :return-value.sync="dates" persistent width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field v-model="dateRange" label="Date Range" prepend-inner-icon="event" solo-inverted flat
                  hide-details="false" class="ma-2" dense readonly v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dates" color="primarygrad" scrollable range>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(dates)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
            <v-btn color="primarygrad white--text" class="mx-2" @click="getMessages()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>

        <v-card-title class="py-3 d-flex align-center justify-space-between flex-wrap">
          <h3 class="font-weight-medium secondary--text">
            Message Reports
          </h3>

          <div>
            <v-chip class="ma-2" color="primary" outlined>
              <v-icon left>
                mdi-check
              </v-icon>
              Sent: {{ totalData.sent }}
            </v-chip>
            <v-chip class="ma-2" color="teal darken-3" outlined>
              <v-icon left>
                mdi-check-all
              </v-icon>
              Delivered: {{ totalData.delivered }}
            </v-chip>
            <v-chip class="ma-2" color="light-blue darken-3" outlined>
              <v-icon left>
                mdi-check-all
              </v-icon>
              Read: {{ totalData.read }}
            </v-chip>
            <v-chip class="ma-2" color="red darken-3" outlined>
              <v-icon left>
                mdi-help-circle
              </v-icon>
              Failed: {{ totalData.failed }}
            </v-chip>
          </div>
        </v-card-title>

        <v-row class="pl-1">
          <v-col xs12>
            <v-data-table :headers="headers" :items="messagesResponse" class="elevation-1">
              <template v-slot:item.sentAt="{ item }">
                {{ formatDate(item.sentAt) }}
              </template>

              <template v-slot:item.content="{ item }">
                <div v-if="item.messageType === 'template'">
                  <p class="font-weight-black my-1 mx-0 subtitle-1">
                    {{ item.data.some((item) => item.type === "HEADER")
        ? item.data.find((item) => item.type === "HEADER")
          .text
        : "" }}
                  </p>
                  <p class="my-1 mx-0 subtitle-1">{{ item.data.find((item) => item.type === "BODY").text }}</p>
                  <p class="font-weight-400 caption my-1 mx-0 subtitle-1">
                    {{ item.data.some((item) => item.type === "FOOTER")
        ? item.data.find((item) => item.type === "FOOTER")
          .text
        : "" }}
                  </p>
                </div>

                <div v-if="item.messageType === 'text'">
                  {{ item.content }}
                </div>

                <div v-if="item.messageType == 'image' && item.fileLink">
                  <img class="compressed" :src="item.fileLink" alt="image" style="width:100px;height: auto;" />
                </div>

                <div v-if="item.messageType == 'contacts'" style="padding-top: 5px;">
                  <div style="padding-left: 50px;">
                    <img src="https://t3.ftcdn.net/jpg/03/53/11/00/360_F_353110097_nbpmfn9iHlxef4EDIhXB1tdTD0lcWhG9.jpg"
                      alt="user" style="width: 20px; height: auto;border-radius: 50%" />
                  </div>

                  <div>
                    {{
        item.data[0].name
          ? item.data[0].name.formatted_name
          : item.data[0].phones[0].phone
      }}
                  </div>
                  <div>
                    {{
          item.data[0].phones
            ? item.data[0].phones[0].phone
            : item.data[0].name.formatted_name
        }}
                  </div>
                </div>

                <div v-if="item.messageType === 'audio' && item.fileLink">
                  <AudioMessage :message="item" :icon-color="iconColor" :status="status"
                    :formatted-time="formattedTime" />
                </div>

                <div v-if="item.messageType === 'text'">
                  {{ item.content }}
                </div>
              </template>

              <template v-slot:item.status="{ item }">
                <v-chip :color="getColor(item.status)" dark>{{ item.status }}</v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumbs from "@/components/breadcrumbs.vue";
import BarChart from "../../js/bar.js";
const _ = require("lodash");
import moment from "moment-timezone";
import AudioMessage from "../../components/WhatsappMessageFormats/AudioMessage.vue";
import WhatsappConnector from "../../components/whatsappConnector.vue";

export default {
  components: {
    Breadcrumbs,
    BarChart,

    AudioMessage,
    WhatsappConnector,
  },
  data() {
    return {
      isConnected: false,
      whatsappRegistered: false,
      progress: 0,
      timeLeft: "",
      audio: null,
      isPlaying: false,
      modal: false,
      templates: [],
      allTemplates: [],
      preloader: false,
      dates: [],
      headers: [
        // { text: "Conversation ID", value: "conversationId" },
        { text: "Contact", value: "conversation.contact.name" },
        // { text: "Sender Type", value: "senderType" },
        { text: "Sent By", value: "sendBy" },
        { text: "Content", value: "content" },
        { text: "Sent At", value: "sentAt" },
        // { text: "Sent To", value: "sentTo" },
        { text: "Status", value: "status" },
        // { text: "Platform Message ID", value: "platformMessageId" },
        // { text: "Company ID", value: "companyId" },
        { text: "Message Type", value: "messageType" },
        // { text: "ID", value: "id" },
      ],
      // Sample campaign data
      messagesResponse: [],

      headersBroadcast: [
        {
          text: "Campaign",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Sent", value: "sent" },
        { text: "Received", value: "received" },
        { text: "Percentage", value: "percentage", sortable: false },
      ],
      // Sample campaign data
      broadcasts: [
        { name: "Campaign A", sent: 200, received: 150 },
        { name: "Campaign B", sent: 500, received: 200 },
        { name: "Campaign C", sent: 800, received: 450 },
        // Add more campaigns as needed
      ],
      //   barChart: {},
      barChart: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Number of Messages",
            backgroundColor: "#818589",
            data: [40, 20, 12, 39, 10, 40, 39, 80, 40],
          },
          {
            label: "Active Time",
            backgroundColor: "#76b852",
            data: [60, 55, 32, 10, 2, 12, 11, 25, 34],
          },
        ],
      },
      totalData: {
        sent: 0,
        delivered: 0,
        read: 0,
        failed: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "dispoList",
      "teams",
      "user",
      "selectedTeam",
      "selectedList",
    ]),

    dateRange() {
      return this.dates.join(" - ");
    },
    formattedTime() {
      return (timeString) => {
        const parsedDate = moment(timeString);
        const hours = parsedDate.format("hh");
        const minutes = parsedDate.format("mm");
        const amPm = parsedDate.format("a");
        return `${hours}:${minutes} ${amPm}`;
      };
    },
    status() {
      return (msgStatus) => {
        switch (msgStatus) {
          case "pending":
            return "mdi-update"; // Example icon for active status
          case "sent":
            return "mdi-check"; // Example icon for inactive status
          case "delivered":
            return "mdi-check-all"; // Example icon for inactive status
          case "read":
            return "mdi-check-all"; // Example icon for inactive status
          case "failed":
            return "mdi-help-circle"; // Default icon
          default:
            return "mdi-help-circle"; // Default icon
        }
      };
    },

    iconColor() {
      return (msgStatus) => {
        return msgStatus != "read"
          ? msgStatus == "failed"
            ? "text-red"
            : "text-grey"
          : "text-blue";
      };
    },
  },

  async mounted() { },
  async created() {
    const getIntegration = await this.$http.get(
      `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.user.companyId}&filter[where][type]=whatsapp`
    );

    if (
      getIntegration.body.length &&
      getIntegration.body[0].config.initializeDone
    ) {
      this.setChatShow();
      this.getTemplates();
      this.getMessages();
      this.setDates();
    }
  },
  beforeDestroy() {
    if (this.audio) {
      this.audio.pause();
      this.audio.removeEventListener("timeupdate", this.updateProgress);
      this.audio.removeEventListener("loadedmetadata", this.calculateTimeLeft);
      this.audio.removeEventListener("ended", this.resetAudio);
    }
  },
  methods: {
    getColor(status) {
      switch (status) {
        case 'sent':
          return 'primary';
        case 'delivered':
          return 'teal darken-3';
        case 'read':
          return 'light-blue darken-3';
        case 'failed':
          return 'red darken-3';
        default:
          return 'grey';
      }
    },
    setChatShow() {
      this.isConnected = true;
      this.whatsappRegistered = true;
    },
    updateProgress(link) {
      if (this.audio == null) {
        this.audio = new Audio(link);
      }

      const currentTime = this.audio.currentTime;
      const duration = this.audio.duration;
      this.progress = (currentTime / duration) * 100;
      this.calculateTimeLeft(duration - currentTime);
    },
    calculateTimeLeft(timeLeft) {
      const mins = Math.floor(timeLeft / 60);
      const secs = Math.floor(timeLeft % 60);
      this.timeLeft = `${mins}:${secs < 10 ? "0" : ""}${secs}`;
    },
    resetAudio(link) {
      if (this.audio == null) {
        this.audio = new Audio(link);
      }

      this.audio.currentTime = 0;
      this.isPlaying = false;
      this.progress = 0;
      this.calculateTimeLeft(this.audio.duration);
    },
    togglePlay(link) {
      if (this.audio == null) {
        this.audio = new Audio(link);
      }

      if (this.audio.paused) {
        this.isPlaying = true;
        this.audio.play();
      } else {
        this.isPlaying = false;
        this.audio.pause();
      }
    },
    setDates() {
      var startDate = moment([moment().year(), moment().month()]).format(
        "YYYY-MM-DD"
      );
      var endDate = moment(startDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      this.dates = [startDate, endDate];

      this.getMessages();
      // this.dateSelected = moment(this.date).daysInMonth();
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleString();
    },
    calculatePercentage(sent, received) {
      return ((received / sent) * 100).toFixed(2);
    },
    filterByLink(active) {
      if (active) {
        this.templates = _.filter(this.allTemplates, (t) => {
          return t.isLink;
        });
        return;
      }

      this.templates = this.allTemplates;
    },

    async getMessages() {
      // Vue.set(this.selectedChat, 'selectedChat', conversation);

      // var startDate = moment(this.dates[0]).format("YYYY-MM-DD");
      // var endDate = moment(this.dates[1]).format("YYYY-MM-DD");

      this.totalData = {
        sent: 0,
        delivered: 0,
        read: 0,
        failed: 0,
      };

      var start = this.dates[0];
      var end = this.dates[1] == undefined ? start : this.dates[1];
      if (start > end) {
        var temp = start;
        start = end;
        end = temp;
      }

      start = `${moment(start).format("YYYY-MM-DDT00:00:01")}`;
      end = `${moment(end).format("YYYY-MM-DDT23:59:59")}`;

      try {
        // const messages = await this.$http.get(
        //   `${this.$store.state.ENDPOINT}/messages?filter[where][and][0][companyId]=${this.$store.state.user.companyId}&filter[where][and][1][sentAt][gte]=${start}&filter[where][and][2][sentAt][lte]=${end}`
        // );

        const filter = encodeURIComponent(
          JSON.stringify({
            where: {
              and: [
                { companyId: this.$store.state.user.companyId },
                { sentAt: { gte: start } },
                { sentAt: { lte: end } },
              ],
            },
            include: {
              relation: "conversation",
              scope: {
                include: {
                  relation: "contact",
                  scope: {
                    fields: ["name"],
                  },
                },
              },
            },
            order: "sentAt DESC",
          })
        );

        const messages = await this.$http.get(
          `${this.$store.state.ENDPOINT}/messages?filter=${filter}`
        );

        this.messagesResponse = messages.body;

        // console.log("messageResponse: ", this.messagesResponse);

        // For testing:  give me only thoose messages which doesnot have a data property
        // const filteredMessages = this.messagesResponse.filter(message => message.messageType === 'template');
        // console.log("filtered messages: ", filteredMessages);

        this.messagesResponse = this.messagesResponse.filter((message) =>
          ["sent", "delivered", "read", "failed"].includes(message.status)
        );

        this.messagesResponse.forEach((message) => {
          switch (message.status) {
            case "sent":
              this.totalData.sent++;
              break;
            case "delivered":
              this.totalData.delivered++;
              break;
            case "read":
              this.totalData.read++;
              break;
            case "failed":
              this.totalData.failed++;
              break;
          }
        });

        // this.wapp.showSendMessage = messages.body.some(
        //   (item) => item.senderType === "contact"
        // );

        // const messagesByDateArray = [];

        // messages.body.forEach((message) => {

        //   const sentAt = new Date(message.sentAt).toLocaleDateString();

        //   const index = messagesByDateArray.findIndex(
        //     (item) => item.date === sentAt
        //   );

        //   if (index === -1) {
        //     messagesByDateArray.push({ date: sentAt, messages: [message] });
        //   } else {
        //     messagesByDateArray[index].messages.push(message);
        //   }
        // });

        // messagesByDateArray.map((item) =>
        //   item.messages.sort((a, b) => new Date(a.sentAt) - new Date(b.sentAt))
        // );

        // Vue.set(this.wapp, "chatHistory", messagesByDateArray);
        // console.log("Messages:, ", this.wapp.chatHistory);
        // this.scrollToBottom();
      } catch (error) {
        console.log("Get message error: ", error);
      }
    },

    getTemplates() {
      var self = this;
      this.preloader = true;
      this.templates = [];
      this.$http
        .get(this.$store.state.ENDPOINT + "/whatsapps/getTemplates")
        .then(
          (response) => {
            self.preloader = false;
            response = response.body;
            if (response && response.data && Array.isArray(response.data)) {
              this.templates = response.data;

              // this.templates = _.filter(response.data, { status: "APPROVED" });
            } else {
              this.$swal({
                type: "error",
                text: "Invalid response format while loading the templates.",
              });
            }
            this.preloader = false;
          },
          (error) => {
            this.preloader = false;
            this.$swal({
              type: "error",
              text: "Something went wrong while loading the templates.",
            });
          }
        );
    },
  },
};
</script>

<style scoped>
.small-chat-img {
  display: none !important;
}
</style>
